import { deletePOSAPI, getPOSAPI, updatePOSAPI } from "@/services";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getPOS = createAsyncThunk("pos/getPOS", async () => {
  try {
    const { data } = await getPOSAPI();
    return data;
  } catch (error: any) {
    console.log("error: ", error);
    throw new Error(error.message);
  }
});

export const updatePOS = createAsyncThunk(
  "pos/updatePOS",
  async (id: number) => {
    try {
      await updatePOSAPI(id);
    } catch (error: any) {
      console.log("error: ", error);
      throw new Error(error.message);
    }
  }
);

export const deletePOS = createAsyncThunk(
  "pos/deletePOS",
  async (id: number) => {
    try {
      await deletePOSAPI(id);
    } catch (error: any) {
      console.log("error: ", error);
      throw new Error(error.message);
    }
  }
);
