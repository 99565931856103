import { ReactNode, useEffect, useState } from "react";
import { Header } from "./Header";
import { Sidebar } from "./Sidebar";

type Props = {
  children: ReactNode;
};

export const Layout = ({ children }: Props) => {
  const layoutType = "vertical";
  const [headerClass, setHeaderClass] = useState("");

  useEffect(() => {
    window.addEventListener("scroll", scrollNavigation, true);
  });

  const scrollNavigation = () => {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > 50) {
      setHeaderClass("topbar-shadow");
    } else {
      setHeaderClass("");
    }
  };

  useEffect(() => {
    const humberIcon = document.querySelector(".hamburger-icon") as HTMLElement;
    if (layoutType === "vertical") {
      humberIcon.classList.remove("open");
      if (document.documentElement) {
        document.documentElement.setAttribute("data-layout", layoutType);
      }
    } else {
      humberIcon && humberIcon.classList.add("open");
    }
  }, [layoutType]);

  return (
    <div id="layout-wrapper">
      <Header headerClass={headerClass} />
      <Sidebar />
      <div className="main-content">{children}</div>
    </div>
  );
};
