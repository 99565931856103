import {
  createUserAPI,
  deleteUserAPI,
  disableUserAPI,
  enableUserAPI,
  getUserDetailsAPI,
  getUsersAPI,
  resetUserPasswordAPI,
  updateUserAPI,
} from "@/services";
import { CreateUserFormType, UpdateUserFormType } from "@/types";
import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";

export const getUsers = createAsyncThunk("user/getUsers", async () => {
  try {
    const { data } = await getUsersAPI();
    const formatData = data.map((dt) => {
      const { subscription } = dt;
      if (subscription?.created_at || subscription?.expose_expire_date) {
        return {
          ...dt,
          subscription: {
            ...subscription,
            created_at: subscription.created_at
              ? moment(subscription.created_at)
              : undefined,
            expose_expire_date: subscription.expose_expire_date
              ? moment(subscription.expose_expire_date)
              : undefined,
          },
        };
      }
      return dt;
    });
    return formatData;
  } catch (error: any) {
    console.log("error: ", error);
    throw new Error(error.message);
  }
});

export const getUserDetails = createAsyncThunk(
  "user/getUserDetails",
  async (id: number) => {
    try {
      const { data } = await getUserDetailsAPI(id);
      return data;
    } catch (error: any) {
      console.log("error: ", error);
      throw new Error(error.message);
    }
  }
);

export const createUser = createAsyncThunk(
  "user/createUser",
  async (data: CreateUserFormType) => {
    try {
      await createUserAPI(data);
    } catch (error: any) {
      console.log("error: ", error);
      throw new Error(error.message);
    }
  }
);

export const updateUser = createAsyncThunk(
  "user/updateUser",
  async (data: UpdateUserFormType) => {
    try {
      await updateUserAPI(data);
    } catch (error: any) {
      console.log("error: ", error);
      throw new Error(error.message);
    }
  }
);

export const enableUser = createAsyncThunk(
  "user/enableUser",
  async (id: number) => {
    try {
      await enableUserAPI(id);
    } catch (error: any) {
      console.log("error: ", error);
      throw new Error(error.message);
    }
  }
);

export const disableUser = createAsyncThunk(
  "user/disableUser",
  async (id: number) => {
    try {
      await disableUserAPI(id);
    } catch (error: any) {
      console.log("error: ", error);
      throw new Error(error.message);
    }
  }
);

export const resetUserPassword = createAsyncThunk(
  "user/resetUserPassword",
  async (id: number) => {
    try {
      await resetUserPasswordAPI(id);
    } catch (error: any) {
      console.log("error: ", error);
      throw new Error(error.message);
    }
  }
);

export const deleteUser = createAsyncThunk(
  "user/deleteUser",
  async (id: number) => {
    try {
      await deleteUserAPI(id);
    } catch (error: any) {
      console.log("error: ", error);
      throw new Error(error.message);
    }
  }
);
