import { useAppDispatch, useAppSelector } from "@/app";
import { LineCharts } from "@/components";
import {
  getCampaignResult,
  getTopSubscribersByProfit,
  getTopSubscribersBySales,
  getTotalAgencyClients,
  getTotalAgencySubscribers,
  getTotalCampaignProfitFromSubscribers,
  getTotalCampaignSalesFromSubscribers,
  getTotalCampaignsFromSubscribers,
  getTotalDIYPaidSubscribers,
  getTotalFreeTrialSubscribers,
  getTotalInactiveSubscribers,
  getTotalIntegrationSubscribers,
  getTotalNewSubscribers,
  getTotalPaidSubscribers,
  getTotalTerminatedSubscribers,
} from "@/features";
import { CampaignType, ChartProps } from "@/types";
import { formatCurrency } from "@/utils";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap";

const ReportDashboard = () => {
  const { campaignResultList } = useAppSelector((state) => state.dashboard);
  const dispatch = useAppDispatch();
  const [data, setData] = useState<CampaignType[]>([]);
  const [total, setTotal] = useState({
    total_customer: 0,
    total_sales: 0,
    total_cost: 0,
    average_sales_per_customer: 0,
    profit_per_customer: 0,
  });
  const [topSubscribersBySales, setTopSubscribersBySales] =
    useState<ChartProps>({
      data: [],
      isLoading: true,
    });
  const [topSubscribersByProfit, setTopSubscribersByProfit] =
    useState<ChartProps>({
      data: [],
      isLoading: true,
    });
  const [totalNewSubscribers, setTotalNewSubscribers] = useState<ChartProps>({
    data: [],
    isLoading: true,
  });
  const [totalTerminatedSubscribers, setTotalTerminatedSubscribers] =
    useState<ChartProps>({
      data: [],
      isLoading: true,
    });
  const [totalFreeTrialSubscribers, setTotalFreeTrialSubscribers] =
    useState<ChartProps>({
      data: [],
      isLoading: true,
    });
  const [totalPaidSubscribers, setTotalPaidSubscribers] = useState<ChartProps>({
    data: [],
    isLoading: true,
  });
  const [totalDIYPaidSubscribers, setTotalDIYPaidSubscribers] =
    useState<ChartProps>({
      data: [],
      isLoading: true,
    });
  const [totalIntegrationSubscribers, setTotalIntegrationSubscribers] =
    useState<ChartProps>({
      data: [],
      isLoading: true,
    });
  const [totalAgencySubscribers, setTotalAgencySubscribers] =
    useState<ChartProps>({
      data: [],
      isLoading: true,
    });
  const [totalAgencyClients, setTotalAgencyClients] = useState<ChartProps>({
    data: [],
    isLoading: true,
  });
  const [totalInactiveSubscribers, setTotalInactiveSubscribers] =
    useState<ChartProps>({
      data: [],
      isLoading: true,
    });
  const [totalCampaignsFromSubscribers, setTotalCampaignsFromSubscribers] =
    useState<ChartProps>({
      data: [],
      isLoading: true,
    });
  const [
    totalCampaignSalesFromSubscribers,
    setTotalCampaignSalesFromSubscribers,
  ] = useState<ChartProps>({
    data: [],
    isLoading: true,
  });
  const [
    totalCampaignProfitFromSubscribers,
    setTotalCampaignProfitFromSubscribers,
  ] = useState<ChartProps>({
    data: [],
    isLoading: true,
  });

  const loadFirstChart = useCallback(async () => {
    try {
      const data = await dispatch(getTopSubscribersBySales()).unwrap();
      setTopSubscribersBySales({
        data,
        isLoading: false,
      });
    } catch (error) {
      console.log("error: ", error);
    }
  }, [dispatch]);

  const loadSecondChart = useCallback(async () => {
    try {
      const data = await dispatch(getTopSubscribersByProfit()).unwrap();
      setTopSubscribersByProfit({
        data,
        isLoading: false,
      });
    } catch (error) {
      console.log("error: ", error);
    }
  }, [dispatch]);

  const loadthirdChart = useCallback(async () => {
    try {
      const data = await dispatch(getTotalNewSubscribers()).unwrap();
      setTotalNewSubscribers({
        data,
        isLoading: false,
      });
    } catch (error) {
      console.log("error: ", error);
    }
  }, [dispatch]);

  const loadFourthChart = useCallback(async () => {
    try {
      const data = await dispatch(getTotalTerminatedSubscribers()).unwrap();
      setTotalTerminatedSubscribers({
        data,
        isLoading: false,
      });
    } catch (error) {
      console.log("error: ", error);
    }
  }, [dispatch]);

  const loadFifthChart = useCallback(async () => {
    try {
      const data = await dispatch(getTotalFreeTrialSubscribers()).unwrap();
      setTotalFreeTrialSubscribers({
        data,
        isLoading: false,
      });
    } catch (error) {
      console.log("error: ", error);
    }
  }, [dispatch]);

  const loadSixthChart = useCallback(async () => {
    try {
      const data = await dispatch(getTotalPaidSubscribers()).unwrap();
      setTotalPaidSubscribers({
        data,
        isLoading: false,
      });
    } catch (error) {
      console.log("error: ", error);
    }
  }, [dispatch]);

  const loadSeventhChart = useCallback(async () => {
    try {
      const data = await dispatch(getTotalDIYPaidSubscribers()).unwrap();
      setTotalDIYPaidSubscribers({
        data,
        isLoading: false,
      });
    } catch (error) {
      console.log("error: ", error);
    }
  }, [dispatch]);

  const loadEighthChart = useCallback(async () => {
    try {
      const data = await dispatch(getTotalIntegrationSubscribers()).unwrap();
      setTotalIntegrationSubscribers({
        data,
        isLoading: false,
      });
    } catch (error) {
      console.log("error: ", error);
    }
  }, [dispatch]);

  const loadNinthChart = useCallback(async () => {
    try {
      const data = await dispatch(getTotalAgencySubscribers()).unwrap();
      setTotalAgencySubscribers({
        data,
        isLoading: false,
      });
    } catch (error) {
      console.log("error: ", error);
    }
  }, [dispatch]);

  const loadTenthChart = useCallback(async () => {
    try {
      const data = await dispatch(getTotalAgencyClients()).unwrap();
      setTotalAgencyClients({
        data,
        isLoading: false,
      });
    } catch (error) {
      console.log("error: ", error);
    }
  }, [dispatch]);

  const loadEleventhChart = useCallback(async () => {
    try {
      const data = await dispatch(getTotalInactiveSubscribers()).unwrap();
      setTotalInactiveSubscribers({
        data,
        isLoading: false,
      });
    } catch (error) {
      console.log("error: ", error);
    }
  }, [dispatch]);

  const loadTwelfthChart = useCallback(async () => {
    try {
      const data = await dispatch(getTotalCampaignsFromSubscribers()).unwrap();
      setTotalCampaignsFromSubscribers({
        data,
        isLoading: false,
      });
    } catch (error) {
      console.log("error: ", error);
    }
  }, [dispatch]);

  const loadThirteenthChart = useCallback(async () => {
    try {
      const data = await dispatch(
        getTotalCampaignSalesFromSubscribers()
      ).unwrap();
      setTotalCampaignSalesFromSubscribers({
        data,
        isLoading: false,
      });
    } catch (error) {
      console.log("error: ", error);
    }
  }, [dispatch]);

  const loadFourteenthChart = useCallback(async () => {
    try {
      const data = await dispatch(
        getTotalCampaignProfitFromSubscribers()
      ).unwrap();
      setTotalCampaignProfitFromSubscribers({
        data,
        isLoading: false,
      });
    } catch (error) {
      console.log("error: ", error);
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(getCampaignResult());
  }, [dispatch]);

  useEffect(() => {
    loadFirstChart();
    loadSecondChart();
    loadthirdChart();
    loadFourthChart();
    loadFifthChart();
    loadSixthChart();
    loadSeventhChart();
    loadEighthChart();
    loadNinthChart();
    loadTenthChart();
    loadEleventhChart();
    loadTwelfthChart();
    loadThirteenthChart();
    loadFourteenthChart();
  }, [
    loadEighthChart,
    loadEleventhChart,
    loadFifthChart,
    loadFirstChart,
    loadFourteenthChart,
    loadFourthChart,
    loadNinthChart,
    loadSecondChart,
    loadSeventhChart,
    loadSixthChart,
    loadTenthChart,
    loadTwelfthChart,
    loadThirteenthChart,
    loadthirdChart,
  ]);

  useEffect(() => {
    const totalCustomer = campaignResultList.reduce(
      (sum, item) => sum + Number(item.total_customer),
      0
    );
    const totalSales = campaignResultList.reduce(
      (sum, item) => sum + parseFloat(item.total_sale.toString()),
      0
    );
    const totalCost = campaignResultList.reduce(
      (sum, item) => sum + parseFloat(item.total_cost.toString()),
      0
    );
    const averageSalesPerCustomer = campaignResultList.reduce(
      (sum, item) =>
        sum + parseFloat(item.average_spend_per_customer.toString()),
      0
    );
    const profitPerCustomer = campaignResultList.reduce(
      (sum, item) => sum + parseFloat(item.profit_per_customer.toString()),
      0
    );

    setTotal({
      total_customer: totalCustomer,
      total_sales: totalSales,
      total_cost: totalCost,
      average_sales_per_customer: averageSalesPerCustomer,
      profit_per_customer: profitPerCustomer,
    });

    setData(campaignResultList);
  }, [campaignResultList]);

  useEffect(() => {
    if (data.length === 0) return;

    setTimeout(() => {
      window.print();
    }, 2000);
  }, [data]);

  return (
    <Container fluid>
      <div>
        <p className="fw-bold fs-4 text-center">
          MARALYTICS - DASHBOARD SUMMARY
        </p>
      </div>
      <div className="d-flex flex-row justify-content-around">
        <p className="fs-4">
          Total Sales:{" "}
          <span className="fw-bold">{formatCurrency(total.total_sales)}</span>
        </p>
        <p className="fs-4">
          Report Date:{" "}
          <span className="fw-bold">{moment().format("DD/MM/YYYY")}</span>
        </p>
      </div>
      <div className="table-responsive mb-5">
        <Table className="table-striped table-bordered align-middle table-nowrap mb-0">
          <thead className="table-light">
            <th scope="col">Name</th>
            <th scope="col">Type</th>
            <th scope="col">Start Date</th>
            <th scope="col">End Date</th>
            <th scope="col">Total Customer</th>
            <th scope="col">Total Sales</th>
            <th scope="col">Total Cost</th>
            <th scope="col">Average Sales Per Customer</th>
            <th scope="col">Profit Per Customer</th>
          </thead>
          <tbody>
            {data.map((item) => {
              return (
                <tr>
                  <td>{item.name}</td>
                  <td>{item.campaign_type.name}</td>
                  <td>{moment(item.start_date).format("DD/MM/YYYY")}</td>
                  <td>{moment(item.end_date).format("DD/MM/YYYY")}</td>
                  <td>{Number(item.total_customer)}</td>
                  <td>{formatCurrency(item.total_sale)}</td>
                  <td>{formatCurrency(item.total_cost)}</td>
                  <td>{formatCurrency(item.average_spend_per_customer)}</td>
                  <td>{formatCurrency(item.profit_per_customer)}</td>
                </tr>
              );
            })}
          </tbody>
          <tfoot className="table-light">
            <tr>
              <td colSpan={4} className="fw-bold">
                Total
              </td>
              <td className="fw-bold">{Number(total.total_customer)}</td>
              <td className="fw-bold">{formatCurrency(total.total_sales)}</td>
              <td className="fw-bold">{formatCurrency(total.total_cost)}</td>
              <td className="fw-bold">
                {formatCurrency(total.average_sales_per_customer)}
              </td>
              <td className="fw-bold">
                {formatCurrency(total.profit_per_customer)}
              </td>
            </tr>
          </tfoot>
        </Table>
      </div>

      <Row>
        <Col lg={6}>
          <Card>
            <CardHeader>
              <h4 className="card-title mb-0">Top Subscribers by Sales</h4>
            </CardHeader>
            <CardBody>
              <div>
                <LineCharts
                  data={topSubscribersBySales.data}
                  isLoading={topSubscribersBySales.isLoading}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col lg={6}>
          <Card>
            <CardHeader>
              <h4 className="card-title mb-0">Top Subscribers by Profit</h4>
            </CardHeader>
            <CardBody>
              <div>
                <LineCharts
                  data={topSubscribersByProfit.data}
                  isLoading={topSubscribersByProfit.isLoading}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col lg={6}>
          <Card>
            <CardHeader>
              <h4 className="card-title mb-0">
                Total New Subscribers per Month (YTD)
              </h4>
            </CardHeader>
            <CardBody>
              <div>
                <LineCharts
                  data={totalNewSubscribers.data}
                  isLoading={totalNewSubscribers.isLoading}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col lg={6}>
          <Card>
            <CardHeader>
              <h4 className="card-title mb-0">
                Total Terminated Subscribers per Month (YTD)
              </h4>
            </CardHeader>
            <CardBody>
              <div>
                <LineCharts
                  data={totalTerminatedSubscribers.data}
                  isLoading={totalTerminatedSubscribers.isLoading}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col lg={6}>
          <Card>
            <CardHeader>
              <h4 className="card-title mb-0">
                Total Free Trial Subscribers per Month (YTD)
              </h4>
            </CardHeader>
            <CardBody>
              <div>
                <LineCharts
                  data={totalFreeTrialSubscribers.data}
                  isLoading={totalFreeTrialSubscribers.isLoading}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col lg={6}>
          <Card>
            <CardHeader>
              <h4 className="card-title mb-0">
                Total Paid Subscribers per Month (YTD)
              </h4>
            </CardHeader>
            <CardBody>
              <div>
                <LineCharts
                  data={totalPaidSubscribers.data}
                  isLoading={totalPaidSubscribers.isLoading}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col lg={6}>
          <Card>
            <CardHeader>
              <h4 className="card-title mb-0">
                Total DIY Paid Subscribers per Month (YTD)
              </h4>
            </CardHeader>
            <CardBody>
              <div>
                <LineCharts
                  data={totalDIYPaidSubscribers.data}
                  isLoading={totalDIYPaidSubscribers.isLoading}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col lg={6}>
          <Card>
            <CardHeader>
              <h4 className="card-title mb-0">
                Total Integration Subscribers per Month (YTD)
              </h4>
            </CardHeader>
            <CardBody>
              <div>
                <LineCharts
                  data={totalIntegrationSubscribers.data}
                  isLoading={totalIntegrationSubscribers.isLoading}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col lg={6}>
          <Card>
            <CardHeader>
              <h4 className="card-title mb-0">
                Total Agency Subscribers per Month (YTD)
              </h4>
            </CardHeader>
            <CardBody>
              <div>
                <LineCharts
                  data={totalAgencySubscribers.data}
                  isLoading={totalAgencySubscribers.isLoading}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col lg={6}>
          <Card>
            <CardHeader>
              <h4 className="card-title mb-0">
                Total Agency Clients per Month (YTD)
              </h4>
            </CardHeader>
            <CardBody>
              <div>
                <LineCharts
                  data={totalAgencyClients.data}
                  isLoading={totalAgencyClients.isLoading}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col lg={6}>
          <Card>
            <CardHeader>
              <h4 className="card-title mb-0">
                Total Inactive Subscribers per Month (YTD)
              </h4>
            </CardHeader>
            <CardBody>
              <div>
                <LineCharts
                  data={totalInactiveSubscribers.data}
                  isLoading={totalInactiveSubscribers.isLoading}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col lg={6}>
          <Card>
            <CardHeader>
              <h4 className="card-title mb-0">
                Total Campaigns from Subscribers per Month (YTD)
              </h4>
            </CardHeader>
            <CardBody>
              <div>
                <LineCharts
                  data={totalCampaignsFromSubscribers.data}
                  isLoading={totalCampaignsFromSubscribers.isLoading}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col lg={6}>
          <Card>
            <CardHeader>
              <h4 className="card-title mb-0">
                Total Campaign Sales from Subscribers per Month (YTD)
              </h4>
            </CardHeader>
            <CardBody>
              <div>
                <LineCharts
                  data={totalCampaignSalesFromSubscribers.data}
                  isLoading={totalCampaignSalesFromSubscribers.isLoading}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col lg={6}>
          <Card>
            <CardHeader>
              <h4 className="card-title mb-0">
                Total Campaign Profit from Subscribers per Month (YTD)
              </h4>
            </CardHeader>
            <CardBody>
              <div>
                <LineCharts
                  data={totalCampaignProfitFromSubscribers.data}
                  isLoading={totalCampaignProfitFromSubscribers.isLoading}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ReportDashboard;
