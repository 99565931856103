import { Bounce, toast } from "react-toastify";

export const toastify = (content: string, type: "success" | "error") => {
  toast[type](content, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: false,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    theme: "light",
    transition: Bounce,
    style: {
      marginTop: 60,
    },
  });
};
