import { isEmpty, isNil } from "lodash";
import numbro from "numbro";

export type EmptyObject = {
  [K in string | number]: never;
};

export const formatCurrency = (value: number) => {
  return `${numbro(value).formatCurrency({
    thousandSeparated: true,
    mantissa: 2,
  })}`;
};

export const formatROI = (value: number) => {
  return `${numbro(value).format({
    thousandSeparated: true,
    mantissa: 2,
  })}%`;
};

export const isNilOrEmpty = (
  value: any
): value is null | undefined | "" | EmptyObject =>
  isNil(value) || isEmpty(value);

export * from "./toastify";
