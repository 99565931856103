import { useAppSelector } from "@/app";
import { PATH_NAME } from "@/constants";
import { Layout } from "@/layouts";
import { Navigate, Outlet } from "react-router-dom";

const AuthProtected = () => {
  const { token } = useAppSelector((state) => state.auth);

  if (!token) {
    return <Navigate to={PATH_NAME.LOGIN} replace />;
  }

  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};

export default AuthProtected;
