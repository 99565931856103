import { POSSliceType } from "@/types";
import { createSlice } from "@reduxjs/toolkit";
import { getPOS } from "./thunk";

const initialState: POSSliceType = {
  POSList: [],
};

export const POSSlice = createSlice({
  name: "POS",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPOS.fulfilled, (state, { payload }) => {
      state.POSList = payload;
    });
  },
});

export const POSReducer = POSSlice.reducer;
