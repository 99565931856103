import { useAppDispatch, useAppSelector } from "@/app";
import { BreadCrumb, TableContainer } from "@/components";
import { PATH_NAME } from "@/constants";
import { getRedemptions } from "@/features";
import { RedemptionType } from "@/types";
import { formatCurrency } from "@/utils";
import { ColumnDef } from "@tanstack/react-table";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
} from "reactstrap";
import { DeleteRedemption } from "./components/DeleteRedemption";

const RedemptionManagement = () => {
  const { redemptionList } = useAppSelector((state) => state.redemption);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isDelete, setIsDelete] = useState(false);
  const [selectedId, setSelectedId] = useState<number | null>(null);

  const columns = useMemo<ColumnDef<RedemptionType>[]>(
    () => [
      {
        header: "Offer Name",
        accessorKey: "promotion_name",
        disableFilters: false,
        enableColumnFilter: false,
      },
      {
        header: "Start Date",
        accessorKey: "created_at",
        disableFilters: true,
        enableColumnFilter: false,
        cell: ({ row }) => moment(row.original.created_at).format("DD/MM/YYYY"),
      },
      {
        header: "Finish Date",
        accessorKey: "end_date",
        disableFilters: true,
        enableColumnFilter: false,
        cell: ({ row }) => moment(row.original.end_date).format("DD/MM/YYYY"),
      },
      {
        header: "Package",
        accessorKey: "package_name",
        disableFilters: true,
        enableColumnFilter: false,
      },
      {
        header: "Price",
        accessorKey: "discount_price",
        disableFilters: true,
        enableColumnFilter: false,
        sortingFn: "alphanumeric",
        cell: ({ row }) => formatCurrency(row.original.discount_price || 0),
      },
      {
        header: "Total Codes",
        accessorKey: "total_code",
        disableFilters: true,
        enableColumnFilter: false,
        headerClass: "text-center",
        rowClass: "text-center",
      },
      {
        header: "Used Codes",
        accessorKey: "used_code",
        enableColumnFilter: false,
        headerClass: "text-center",
        rowClass: "text-center",
      },
      {
        header: "Status",
        accessorKey: "status",
        disableFilters: true,
        enableColumnFilter: false,
        cell: ({ row }) => {
          return (
            <Badge
              color={row.original.status ? "success" : "danger"}
              style={{ fontSize: 13 }}
            >
              {row.original.status ? "Active" : "Inactive"}
            </Badge>
          );
        },
      },
      {
        header: "Action",
        disableFilters: true,
        enableColumnFilter: false,
        enableSorting: false,
        cell: ({ row }) => {
          return (
            <div>
              <Button
                color="primary"
                className="btn-sm"
                style={{ minWidth: 58 }}
                onClick={() =>
                  navigate(
                    `${PATH_NAME.REDEMPTION_MANAGEMENT}/view/${row.original.id}`
                  )
                }
              >
                View
              </Button>
              <Button
                color="primary"
                className="btn-sm ms-2"
                style={{ minWidth: 58 }}
                onClick={() =>
                  navigate(
                    `${PATH_NAME.REDEMPTION_MANAGEMENT}/edit/${row.original.id}`
                  )
                }
              >
                Edit
              </Button>
              <Button
                color="danger"
                className="btn-sm ms-2"
                style={{ minWidth: 58 }}
                onClick={() => {
                  setSelectedId(row.original.id);
                  setIsDelete(true);
                }}
              >
                Delete
              </Button>
            </div>
          );
        },
      },
    ],
    [navigate]
  );

  useEffect(() => {
    dispatch(getRedemptions());
  }, [dispatch]);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Redemption Management" />
          <Card>
            <CardHeader className="text-end">
              <Button
                color="primary"
                onClick={() => navigate(PATH_NAME.CREATE_REDEMPTION_MANAGEMENT)}
              >
                <span>
                  <i className="ri-add-line align-bottom me-1" />
                  Create New Redemption
                </span>
              </Button>
            </CardHeader>
            <CardBody>
              <TableContainer
                columns={columns || []}
                data={redemptionList || []}
                customPageSize={10}
                tableClass="table-centered align-middle table-nowrap mb-0"
                theadClass="text-muted table-light"
                divClass="table-responsive"
              />
            </CardBody>
          </Card>
        </Container>
      </div>

      <DeleteRedemption
        isOpen={isDelete}
        toggle={() => setIsDelete(!isDelete)}
        selectedId={selectedId as number}
        onSuccess={() => dispatch(getRedemptions())}
      />
    </>
  );
};

export default RedemptionManagement;
