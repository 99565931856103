import { Api } from "@/networking";
import { POSType } from "@/types";

export const getPOSAPI = () => {
  return Api.GET_POS<POSType[]>("");
};

export const updatePOSAPI = (id: number) => {
  return Api.UPDATE_POS<{}>("", {
    urlParams: {
      id,
    },
  });
};

export const deletePOSAPI = (id: number) => {
  return Api.DELETE_POS<{}>("", {
    urlParams: {
      id,
    },
  });
};
