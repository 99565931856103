import { useAppDispatch } from "@/app";
import { deleteClient } from "@/features";
import { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

type Props = {
  isOpen: boolean;
  id: number;
  client_id: number;
  onSuccess: () => void;
  toggle: () => void;
};

export const DeleteClient = ({
  isOpen,
  id,
  client_id,
  onSuccess,
  toggle,
}: Props) => {
  const dispatch = useAppDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async () => {
    setIsSubmitting(true);
    try {
      await dispatch(deleteClient({ id, client_id })).unwrap();
      onSuccess();
      toggle();
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Confirm Delete Client</ModalHeader>
      <ModalBody>
        <p>
          Are you sure want to <span className="fw-bold">DELETE</span> this
          client?
        </p>
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={() => toggle()} disabled={isSubmitting}>
          Close
        </Button>
        <Button
          color="danger"
          onClick={() => onSubmit()}
          disabled={isSubmitting}
        >
          Yes
        </Button>
      </ModalFooter>
    </Modal>
  );
};
