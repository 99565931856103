import { useAppDispatch } from "@/app";
import { updatePOS } from "@/features";
import { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

type Props = {
  isOpen: boolean;
  selectedId: number;
  status: "active" | "inactive";
  onSuccess: () => void;
  toggle: () => void;
};

export const UpdatePOS = ({
  isOpen,
  selectedId,
  status,
  onSuccess,
  toggle,
}: Props) => {
  const dispatch = useAppDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onUpdate = async () => {
    setIsSubmitting(true);
    try {
      await dispatch(updatePOS(selectedId)).unwrap();
      onSuccess();
      toggle();
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        Confirm {status === "active" ? "Inactive" : "Active"}
      </ModalHeader>
      <ModalBody>
        <p>
          Are you sure want to{" "}
          <span className="fw-bold">
            {status === "active" ? "INACTIVE" : "ACTIVE"}
          </span>{" "}
          this POS?
        </p>
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={() => toggle()} disabled={isSubmitting}>
          Close
        </Button>
        <Button
          color="primary"
          onClick={() => onUpdate()}
          disabled={isSubmitting}
        >
          Yes
        </Button>
      </ModalFooter>
    </Modal>
  );
};
