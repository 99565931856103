import { useAppDispatch, useAppSelector } from "@/app";
import { BreadCrumb, TableContainer } from "@/components";
import { POSOptions } from "@/constants";
import { getPOS } from "@/features";
import { OptionProps, POSType } from "@/types";
import { ColumnDef } from "@tanstack/react-table";
import { useEffect, useMemo, useState } from "react";
import Select from "react-select";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { DeletePOS } from "./components/DeletePOS";
import { UpdatePOS } from "./components/UpdatePOS";
import { FilterProps } from "./types";

const POSManagement = () => {
  const { POSList } = useAppSelector((state) => state.pos);
  const dispatch = useAppDispatch();
  const [data, setData] = useState<POSType[]>([]);
  const [filter, setFilter] = useState<FilterProps>({
    store: "",
    type: null,
  });
  const [status, setStatus] = useState<boolean | null>(null);
  const [isDelete, setIsDelete] = useState(false);
  const [selectedId, setSelectedId] = useState<number | null>(null);

  const posColumns = useMemo<ColumnDef<POSType>[]>(
    () => [
      {
        header: "ID",
        accessorKey: "id",
        disableFilters: true,
        enableColumnFilter: false,
      },
      {
        header: "Type",
        accessorKey: "type",
        disableFilters: true,
        enableColumnFilter: false,
      },
      {
        header: "Store",
        accessorKey: "store",
        disableFilters: true,
        enableColumnFilter: false,
      },
      {
        header: "Locations",
        accessorKey: "locations",
        disableFilters: true,
        enableColumnFilter: false,
      },
      {
        header: "Campaign",
        accessorKey: "campaign_ids",
        disableFilters: true,
        enableColumnFilter: false,
      },
      {
        header: "Connection Status",
        accessorKey: "connect_status",
        disableFilters: true,
        enableColumnFilter: false,
        cell: ({ row }) => {
          return (
            <Badge
              color={
                row.original.token_status === "Valid" ? "success" : "danger"
              }
              style={{ fontSize: 13 }}
            >
              {row.original.token_status === "Valid"
                ? "Connected"
                : "Disconnected"}
            </Badge>
          );
        },
      },
      {
        header: "Access Token Status",
        accessorKey: "token_status",
        disableFilters: true,
        enableColumnFilter: false,
        cell: ({ row }) => {
          return (
            <Badge
              color={row.original.connect_status ? "success" : "danger"}
              style={{ fontSize: 13 }}
            >
              {row.original.connect_status ? "Active" : "Inactive"}
            </Badge>
          );
        },
      },
      {
        header: "Active",
        accessorKey: "active",
        disableFilters: true,
        enableColumnFilter: false,
        cell: ({ row }) => {
          return (
            <Badge
              color={row.original.active ? "success" : "danger"}
              style={{ fontSize: 13 }}
            >
              {row.original.active ? "Active" : "Inactive"}
            </Badge>
          );
        },
      },
      {
        header: "Action",
        disableFilters: true,
        enableColumnFilter: false,
        cell: ({ row }) => {
          return (
            <div>
              <Button
                color="primary"
                className="btn-sm"
                style={{ minWidth: 58 }}
                onClick={() => {
                  setSelectedId(row.original.id as number);
                  setStatus(row.original.active);
                }}
              >
                {row.original.active ? "Inactive" : "Active"}
              </Button>
              <Button
                color="danger"
                className="btn-sm ms-2"
                style={{ minWidth: 58 }}
                onClick={() => {
                  setSelectedId(row.original.id as number);
                  setIsDelete(true);
                }}
              >
                Delete
              </Button>
            </div>
          );
        },
      },
    ],
    []
  );

  const onApply = () => {
    let filteredData = POSList;

    if (filter.store?.trim()) {
      filteredData = filteredData.filter((item: POSType) =>
        item.store?.toLowerCase()?.includes(filter.store.toLowerCase()?.trim())
      );
    }

    if (filter.type) {
      filteredData = filteredData.filter(
        (item: POSType) => item.type === filter.type?.value
      );
    }
    setData(filteredData);
  };

  const onReset = () => {
    setFilter({
      store: "",
      type: null,
    });
    setData(POSList);
  };

  useEffect(() => {
    setData(POSList);
  }, [POSList]);

  useEffect(() => {
    dispatch(getPOS());
  }, [dispatch]);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="POS Management" />
          <Card>
            <CardBody>
              <Row>
                <Col xs={12} lg={3}>
                  <FormGroup>
                    <Label>Store</Label>
                    <div className="form-icon right">
                      <Input
                        placeholder="Search by store"
                        value={filter.store}
                        onChange={(e) =>
                          setFilter({
                            ...filter,
                            store: e.target.value,
                          })
                        }
                      />
                      <i className="ri-search-line" />
                    </div>
                  </FormGroup>
                </Col>
                <Col xs={12} lg={3}>
                  <FormGroup>
                    <Label>Type</Label>
                    <Select
                      options={POSOptions}
                      value={filter.type}
                      placeholder="Select type"
                      onChange={(item: OptionProps) => {
                        setFilter({
                          ...filter,
                          type: item,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} lg={3} className="mt-auto">
                  <FormGroup>
                    <Button color="primary" onClick={onApply}>
                      Apply
                    </Button>
                    <Button color="light" className="ms-2" onClick={onReset}>
                      Reset
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Card>
            <CardHeader>
              <h4 className="card-title mb-0 flex-grow-1">
                Point Of Sales List
              </h4>
            </CardHeader>
            <CardBody>
              <TableContainer
                columns={posColumns || []}
                data={data || []}
                customPageSize={10}
                tableClass="table-centered align-middle table-nowrap mb-0"
                theadClass="text-muted table-light"
                divClass="table-responsive"
              />
            </CardBody>
          </Card>
        </Container>
      </div>

      <UpdatePOS
        isOpen={status !== null}
        status={status ? "active" : "inactive"}
        selectedId={selectedId as number}
        toggle={() => setStatus(null)}
        onSuccess={() => dispatch(getPOS())}
      />

      <DeletePOS
        isOpen={isDelete}
        selectedId={selectedId as number}
        toggle={() => setIsDelete(!isDelete)}
        onSuccess={() => dispatch(getPOS())}
      />
    </>
  );
};

export default POSManagement;
