import { useAppDispatch } from "@/app";
import { BreadCrumb } from "@/components";
import { PATH_NAME } from "@/constants";
import {
  createUser,
  getUserDetails,
  resetUserPassword,
  updateUser,
} from "@/features";
import { UpdateUserFormType } from "@/types";
import { toastify } from "@/utils";
import { Formik, FormikProps } from "formik";
import { useCallback, useEffect, useRef } from "react";
import Flatpickr from "react-flatpickr";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import * as Yup from "yup";

const CAEUserManagement = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const formikRef = useRef<FormikProps<UpdateUserFormType>>(null);

  const getUserInformation = useCallback(
    async (id: string) => {
      try {
        const details = await dispatch(getUserDetails(Number(id))).unwrap();
        formikRef.current?.setValues({
          id: details.id,
          first_name: details.first_name,
          last_name: details.last_name,
          email: details.email,
          subscription_status: details.subscription?.status_name ?? "",
          subscription_expire_date:
            details.subscription?.expose_expire_date ?? undefined,
          password: "",
          password_confirmation: "",
        });
      } catch (error: any) {
        console.log("error: ", error);
        throw new Error(error.message);
      }
    },
    [dispatch]
  );

  const onResetPassword = async () => {
    if (id) {
      try {
        await dispatch(resetUserPassword(Number(id))).unwrap();
        toastify("Email has been sent to user successfully", "success");
      } catch (error) {
        console.log("error: ", error);
        toastify("Email sent unsuccessfully", "error");
      }
    }
  };

  useEffect(() => {
    if (id) {
      getUserInformation(id);
    }
  }, [getUserInformation, id]);

  return (
    <Formik
      initialValues={{
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        password_confirmation: "",
        subscription_status: "",
        subscription_expire_date: undefined,
      }}
      enableReinitialize
      innerRef={formikRef}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Invalid email")
          .required("Please fill out this field."),
        password: Yup.string().when("id", (id, schema) =>
          id ? schema : schema.required("Please fill out this field.")
        ),
        password_confirmation: Yup.string().when("id", (id, schema) =>
          id
            ? schema
            : schema
                .oneOf([Yup.ref("password")], "Passwords do not match.")
                .required("Please fill out this field.")
        ),
      })}
      onSubmit={async (values) => {
        try {
          if (id) {
            values.id = Number(id);
            await dispatch(
              updateUser({
                id: Number(id),
                first_name: values.first_name,
                last_name: values.last_name,
                email: values.email,
                subscription_status: values.subscription_status,
                subscription_expire_date: values.subscription_expire_date,
              })
            ).unwrap();
          } else {
            await dispatch(createUser(values)).unwrap();
          }
          navigate(PATH_NAME.USER_MANAGEMENT);
        } catch (error) {
          console.log("error: ", error);
        } finally {
          formikRef.current?.setSubmitting(false);
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        handleChange,
        handleSubmit,
        setFieldValue,
      }) => (
        <div className="page-content">
          <Container fluid>
            <BreadCrumb title={`${id ? "Edit" : "Create New"} User`} />
            <Card>
              <CardBody>
                <Row>
                  <Col xs={12} lg={6}>
                    <FormGroup>
                      <Label>First Name</Label>
                      <Input
                        name="first_name"
                        placeholder="First Name"
                        value={values.first_name}
                        onChange={handleChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} lg={6}>
                    <FormGroup>
                      <Label>Last Name</Label>
                      <Input
                        name="last_name"
                        placeholder="Last Name"
                        value={values.last_name}
                        onChange={handleChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} lg={6}>
                    <FormGroup>
                      <Label className="required">Email</Label>
                      <Input
                        name="email"
                        placeholder="Email"
                        value={values.email}
                        onChange={handleChange}
                      />
                      {errors.email && touched.email && (
                        <p className="text-danger mt-2">{errors.email}</p>
                      )}
                    </FormGroup>
                  </Col>
                  {!id && (
                    <>
                      <Col xs={12} lg={6}>
                        <FormGroup>
                          <Label className="required">Password</Label>
                          <Input
                            type="password"
                            name="password"
                            placeholder="Password"
                            value={values.password}
                            onChange={handleChange}
                          />
                          {errors.password && touched.password && (
                            <p className="text-danger mt-2">
                              {errors.password}
                            </p>
                          )}
                        </FormGroup>
                      </Col>
                      <Col xs={12} lg={6}>
                        <FormGroup>
                          <Label className="required">Confirmed Password</Label>
                          <Input
                            type="password"
                            name="password_confirmation"
                            placeholder="Confirmed Password"
                            value={values.password_confirmation}
                            onChange={handleChange}
                          />
                          {errors.password_confirmation &&
                            touched.password_confirmation && (
                              <p className="text-danger mt-2">
                                {errors.password_confirmation}
                              </p>
                            )}
                        </FormGroup>
                      </Col>
                    </>
                  )}
                  {id && (
                    <>
                      <Col xs={12} lg={6}>
                        <FormGroup>
                          <Label>Trial Month</Label>
                          <Input
                          // name="password_confirmation"
                          // placeholder="Confirmed Password"
                          // value={values.password_confirmation}
                          // onChange={handleChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={6}>
                        <FormGroup>
                          <Label>Expired Date</Label>
                          <Flatpickr
                            name="password_confirmation"
                            className="form-control"
                            options={{
                              dateFormat: "d/m/Y",
                            }}
                            placeholder="Users was created from"
                            value={values.subscription_expire_date ?? undefined}
                            onChange={(_, date) =>
                              setFieldValue("subscription_expire_date", date)
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col
                        xs={12}
                        md={6}
                        className="d-flex align-items-center mt-2"
                      >
                        <Button onClick={onResetPassword}>
                          Reset Password
                        </Button>
                      </Col>
                    </>
                  )}
                  {id && (
                    <Col xs={12} md={12}>
                      <FormGroup>
                        <Label>Subcription Status</Label>
                        <FormGroup>
                          <Label className="me-4">
                            <Input
                              name="radio-status"
                              type="radio"
                              className="me-2"
                              checked={
                                values.subscription_status?.toLowerCase() ===
                                "active"
                              }
                              onChange={() =>
                                setFieldValue("subscription_status", "active")
                              }
                            />
                            Active
                          </Label>
                          <Label className="me-4">
                            <Input
                              name="radio-status"
                              type="radio"
                              className="me-2"
                              checked={
                                values.subscription_status?.toLowerCase() ===
                                "expired"
                              }
                              onChange={() =>
                                setFieldValue("subscription_status", "expired")
                              }
                            />
                            Expired
                          </Label>
                          <Label className="me-4">
                            <Input
                              name="radio-status"
                              type="radio"
                              className="me-2"
                              checked={
                                values.subscription_status?.toLowerCase() ===
                                "terminated"
                              }
                              onChange={() =>
                                setFieldValue(
                                  "subscription_status",
                                  "terminated"
                                )
                              }
                            />
                            Terminated
                          </Label>
                          <Label className="me-4">
                            <Input
                              name="radio-status"
                              type="radio"
                              className="me-2"
                              checked={
                                values.subscription_status?.toLowerCase() ===
                                "revoke"
                              }
                              onChange={() =>
                                setFieldValue("subscription_status", "revoke")
                              }
                            />
                            Revoke
                          </Label>
                        </FormGroup>
                      </FormGroup>
                    </Col>
                  )}
                </Row>
              </CardBody>
            </Card>
          </Container>
          <div className="text-center">
            <Button
              color="light"
              className="me-2"
              onClick={() => navigate(PATH_NAME.USER_MANAGEMENT)}
              disabled={isSubmitting}
            >
              <span>
                <i className="ri-forbid-2-line me-1" />
                Cancel
              </span>
            </Button>
            <Button
              color="primary"
              onClick={() => handleSubmit()}
              disabled={isSubmitting}
            >
              <span>
                <i className="ri-add-line me-1" />
                {id ? "Update" : "Create"}
              </span>
            </Button>
          </div>
        </div>
      )}
    </Formik>
  );
};

export default CAEUserManagement;
