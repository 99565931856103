import { useAppDispatch } from "@/app";
import { deletePOS } from "@/features";
import { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

type Props = {
  isOpen: boolean;
  selectedId: number;
  toggle: () => void;
  onSuccess: () => void;
};

export const DeletePOS = ({ isOpen, selectedId, toggle, onSuccess }: Props) => {
  const dispatch = useAppDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onDelete = async () => {
    setIsSubmitting(true);
    try {
      await dispatch(deletePOS(selectedId)).unwrap();
      onSuccess();
      toggle();
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Confirm Delete Redemption</ModalHeader>
      <ModalBody>
        <p>
          Are you sure want to <span className="fw-bold">DELETE</span> this POS?
        </p>
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={() => toggle()} disabled={isSubmitting}>
          Close
        </Button>
        <Button
          color="danger"
          onClick={() => onDelete()}
          disabled={isSubmitting}
        >
          Yes
        </Button>
      </ModalFooter>
    </Modal>
  );
};
