import { useAppDispatch, useAppSelector } from "@/app";
import { BreadCrumb, TableContainer } from "@/components";
import { PATH_NAME, StatusOptions } from "@/constants";
import { getSubscriptions } from "@/features";
import { OptionProps, SubscriptionType } from "@/types";
import { formatCurrency } from "@/utils";
import { ColumnDef } from "@tanstack/react-table";
import { capitalize } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { DeleteSubscription } from "./components/DeleteSubscription";
import { EADisableSubscription } from "./components/DisableSubscription";
import { FilterProps } from "./types";

const SubscriptionManagement = () => {
  const { subscriptionList } = useAppSelector((state) => state.subscription);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState<SubscriptionType[]>([]);
  const [filter, setFilter] = useState<FilterProps>({
    subscriptionName: "",
    status: null,
  });
  const [isOpen, setIsOpen] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [selectedId, setSelectedId] = useState<number | null>(null);

  const subscriptionColumns = useMemo<ColumnDef<SubscriptionType>[]>(
    () => [
      {
        header: "Subscription Name",
        accessorKey: "name",
        disableFilters: true,
        enableColumnFilter: false,
      },
      {
        header: "Monthly Price",
        accessorKey: "monthly_price",
        disableFilters: true,
        enableColumnFilter: false,
        cell: ({ row }) => formatCurrency(row.original.monthly_price),
      },
      {
        header: "Annual Price",
        accessorKey: "annually_price",
        disableFilters: true,
        enableColumnFilter: false,
        cell: ({ row }) => formatCurrency(row.original.annually_price),
      },
      {
        header: "Trial",
        disableFilters: true,
        enableColumnFilter: false,
        cell: ({ row }) => {
          return (
            <div>
              {row.original.is_trial_allowed ? (
                <i className="ri-checkbox-circle-fill fs-22 text-primary" />
              ) : (
                <i className="ri-close-circle-fill fs-22 text-danger" />
              )}
            </div>
          );
        },
      },
      {
        header: "Special",
        disableFilters: true,
        enableColumnFilter: false,
        cell: ({ row }) => {
          return (
            <div>
              {row.original.is_special ? (
                <i className="ri-checkbox-circle-fill fs-22 text-primary" />
              ) : (
                <i className="ri-close-circle-fill fs-22 text-danger" />
              )}
            </div>
          );
        },
      },
      {
        header: "Status",
        accessorKey: "status_in_words",
        disableFilters: true,
        enableColumnFilter: false,
        cell: ({ row }) => {
          return (
            <Badge
              color={
                row.original.status_in_words?.toLowerCase() === "active"
                  ? "success"
                  : "danger"
              }
              style={{ fontSize: 13 }}
            >
              {capitalize(row.original.status_in_words)}
            </Badge>
          );
        },
      },
      {
        header: "Action",
        disableFilters: true,
        enableColumnFilter: false,
        cell: ({ row }) => {
          return (
            <div>
              <Button
                color="primary"
                className="btn-sm"
                style={{ minWidth: 58 }}
                onClick={() => {
                  setSelectedId(row.original.id as number);
                  setIsOpen(true);
                  if (row.original.status_in_words.toLowerCase() === "active") {
                    setIsDisable(true);
                  } else {
                    setIsDisable(false);
                  }
                }}
              >
                {row.original.status_in_words.toLowerCase() === "active"
                  ? "Disable"
                  : "Enable"}
              </Button>
              <Button
                color="primary"
                className="btn-sm ms-2"
                style={{ minWidth: 58 }}
                onClick={() =>
                  navigate(
                    `${PATH_NAME.SUBSCRIPTION_MANAGEMENT}/edit/${row.original.id}`
                  )
                }
              >
                Edit
              </Button>
              <Button
                color="danger"
                className="btn-sm ms-2"
                style={{ minWidth: 58 }}
                onClick={() => {
                  setSelectedId(row.original.id as number);
                  setIsDelete(true);
                }}
              >
                Delete
              </Button>
            </div>
          );
        },
      },
    ],
    [navigate]
  );

  const onApply = () => {
    let filteredData = subscriptionList;

    if (filter.subscriptionName?.trim()) {
      filteredData = filteredData.filter((item: SubscriptionType) =>
        item.name
          .toLowerCase()
          .includes(filter.subscriptionName.toLowerCase()?.trim())
      );
    }

    if (filter.status) {
      filteredData = filteredData.filter(
        (item: SubscriptionType) =>
          item.status_in_words.toLowerCase() === filter.status?.value
      );
    }
    setData(filteredData);
  };

  const onReset = () => {
    setFilter({
      subscriptionName: "",
      status: null,
    });
    setData(subscriptionList);
  };

  useEffect(() => {
    setData(subscriptionList);
  }, [subscriptionList]);

  useEffect(() => {
    dispatch(getSubscriptions());
  }, [dispatch]);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Subscription Management" />
          <Card>
            <CardBody>
              <Row>
                <Col xs={12} lg={3}>
                  <FormGroup>
                    <Label>Subscription name</Label>
                    <div className="form-icon right">
                      <Input
                        placeholder="Search by subscription name"
                        value={filter.subscriptionName}
                        onChange={(e) =>
                          setFilter({
                            ...filter,
                            subscriptionName: e.target.value,
                          })
                        }
                      />
                      <i className="ri-search-line" />
                    </div>
                  </FormGroup>
                </Col>
                <Col xs={12} lg={3}>
                  <FormGroup>
                    <Label>Status</Label>
                    <Select
                      options={StatusOptions}
                      value={filter.status}
                      placeholder="Select status"
                      onChange={(item: OptionProps) => {
                        setFilter({
                          ...filter,
                          status: item,
                        });
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} lg={3} className="mt-auto">
                  <FormGroup>
                    <Button color="primary" onClick={onApply}>
                      Apply
                    </Button>
                    <Button color="light" className="ms-2" onClick={onReset}>
                      Reset
                    </Button>
                  </FormGroup>
                </Col>
                <Col xs={12} lg={3} className="mt-auto">
                  <FormGroup className="d-flex flex-row justify-content-lg-end">
                    <Button
                      color="primary"
                      onClick={() =>
                        navigate(PATH_NAME.CREATE_SUBSCRIPTION_MANAGEMENT)
                      }
                    >
                      <i className="ri-add-line me-2" />
                      Create New Subscription
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Card>
            <CardHeader>
              <h4 className="card-title mb-0 flex-grow-1">Subscription List</h4>
            </CardHeader>
            <CardBody>
              <TableContainer
                columns={subscriptionColumns || []}
                data={data || []}
                customPageSize={10}
                tableClass="table-centered align-middle table-nowrap mb-0"
                theadClass="text-muted table-light"
                divClass="table-responsive"
              />
            </CardBody>
          </Card>
        </Container>
      </div>

      <EADisableSubscription
        isOpen={isOpen}
        toggle={() => setIsOpen(false)}
        isDisable={isDisable}
        selectedId={selectedId as number}
        onSuccess={() => dispatch(getSubscriptions())}
      />

      <DeleteSubscription
        isOpen={isDelete}
        toggle={() => setIsDelete(false)}
        selectedId={selectedId as number}
        onSuccess={() => dispatch(getSubscriptions())}
      />
    </>
  );
};

export default SubscriptionManagement;
