import {
  agencyReducer,
  authReducer,
  dashboardReducer,
  POSReducer,
  redemptionReducer,
  subscriptionReducer,
  userReducer,
} from "@/features";
import { combineReducers } from "@reduxjs/toolkit";

export const allReducers = combineReducers({
  auth: authReducer,
  agency: agencyReducer,
  user: userReducer,
  subscription: subscriptionReducer,
  redemption: redemptionReducer,
  dashboard: dashboardReducer,
  pos: POSReducer,
});
