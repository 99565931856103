export const StatusOptions = [
  { label: "Active", value: "active" },
  { label: "Inactive", value: "inactive" },
];

export const SubscriptionOptions = [
  { value: "Free Trial", label: "Free Trial" },
  { value: "D.I.Y (Do It Yourself)", label: "D.I.Y (Do It Yourself)" },
  { value: "Integration", label: "Integration" },
  { value: "Agency", label: "Agency" },
];

export const PackageKeyOptions = [
  { value: "basic_package", label: "Basic Package" },
  { value: "special_basic_package", label: "Special Basic Package" },
  { value: "integration_package", label: "Integration Package" },
  {
    value: "special_integration_package",
    label: "Special Integration Package",
  },
  { value: "free_trial_package", label: "Free Trial" },
];

export const POSOptions = [
  { value: "Xero", label: "Xero" },
  { value: "Shopify", label: "Shopify" },
  { value: "Lightspeed", label: "Lightspeed" },
  { value: "Square", label: "Square" },
  { value: "Vend", label: "Vend" },
  { value: "Vend", label: "Vend" },
];
